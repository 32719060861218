import { UserDataOrganization } from "app/types/userData";
import { LDContext } from "launchdarkly-react-client-sdk";

type Params = {
  userId: string;
  employeeId?: string;
  organizations?: UserDataOrganization[];
  vendorId?: string;
  ppcCampaignId?: string;
  salesChannel?: string;
};

const createAnonymousLDContext = (): LDContext => {
  return {
    kind: "user",
    anonymous: true,
  };
};

export const createLDContext = ({
  userId,
  organizations = [],
  employeeId,
  ...params
}: Params): LDContext => {
  if (!userId) {
    return createAnonymousLDContext();
  }

  const user = {
    internal: Boolean(employeeId),
    key: userId,
    ...params,
  };

  if (!organizations.length) {
    return {
      kind: "user",
      user,
    };
  }

  return {
    kind: "multi",
    user,
    // adding the first organization because we cannot add an array to the context
    organization: {
      key: organizations[0].id,
      enterpriseVendorId: organizations[0].enterpriseVendorId,
    },
  };
};
