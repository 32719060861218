import { RemixBrowser } from "@remix-run/react";
import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import { asyncWithLDProvider, LDContext } from "launchdarkly-react-client-sdk";
import { datadogRum } from "@datadog/browser-rum";

import getEnv from "~/utils/env";

import { hydrateI18n, I18nClientProvider } from "app/i18n/ClientEntry.remix";
import { createLDContext } from "./utils/launchDarkly.client";
import { LoaderType } from "./routes/__vendor-portal";

async function hydrate() {
  const rootLoaderData = __remixContext.state.loaderData?.[
    "routes/__vendor-portal"
  ] as LoaderType;

  const ldContext = createLDContext({
    userId: rootLoaderData?.session?.userId,
    employeeId: String(rootLoaderData?.session?.employeeId),
    organizations: rootLoaderData?.session?.organizations,
    vendorId: String(rootLoaderData?.session?.enterpriseVendorId),
    ppcCampaignId: rootLoaderData?.session?.selectedPPCCampaign?.id
      ? String(rootLoaderData?.session?.selectedPPCCampaign.id)
      : undefined,
    salesChannel: rootLoaderData.session.campaignSummary?.salesChannel,
  });

  const LDProvider = await launchdarklyProvider(ldContext);

  if (getEnv().REACT_APP_MSW_MOCKED === "1") {
    await import("~/mocks/browser");
  }

  await hydrateI18n();

  // TODO: Remix Migration - re-enable StrictMode once the /oauth2/idpresponse route is migrated to a Remix route
  startTransition(() => {
    hydrateRoot(
      document.getElementById("root")!,
      <LDProvider>
        <I18nClientProvider>
          {/* <StrictMode> */}
          <RemixBrowser />
          {/* </StrictMode> */}
        </I18nClientProvider>
      </LDProvider>,
    );

    document.head.innerHTML = document.head.innerHTML.replace(
      /<!--start head-->.+<!--end head-->/,
      "",
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}

const launchdarklyProvider = (context?: LDContext) =>
  asyncWithLDProvider({
    clientSideID: getEnv().REACT_APP_LAUNCH_DARKLY_CLIENT_ID || "",
    options: {
      inspectors: [
        {
          type: "flag-used",
          name: "dd-inspector",
          method: (key: string, detail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
      // disables real-time updates of feature flags
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    context,
  });
